@tailwind base;
@tailwind components;

a:focus, button:focus, input:focus, select:focus, textarea:focus {
  @apply outline-none;
}

.rich-text h1 {
  @apply text-2xl font-semibold mb-4;
}

.rich-text h2 {
  @apply text-xl font-semibold mb-4;
}

.rich-text h3 {
  @apply text-lg font-semibold mb-4;
}

.rich-text h4 {
  @apply text-base font-semibold mb-2;
}

.rich-text h5 {
  @apply text-sm font-semibold mb-2;
}

.rich-text h6 {
  @apply text-xs font-semibold mb-2;
}

.rich-text ul {
  @apply list-disc ml-4;
}

.rich-text ul > ul {
  list-style-type: circle;
}

.rich-text ul > ul > ul {
  list-style-type: square;
}

.rich-text li {
  @apply my-2;
}

.rich-text p {
  @apply mb-4;
}

.top-x {
  top: 1px;
}

.focus\:form-element-shadow:focus {
  box-shadow: 0 0 8px rgba(30, 170, 241, 0.33);
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  animation: effect-fade-in-up 0.25s;
  background-color: white;
  background-position: center center;
  background-repeat: repeat-x;
  border-radius: 3px;
  box-shadow: 7px 7px 27px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.15);
  margin: 9px 0 0;
  padding: 7px 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  width: inherit;
  z-index: 10;
}

.dropdown-content::before {
  border-bottom: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  right: 10px;
  top: -7px;
  width: 0;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
  padding: 5px 0;
}

.dropdown-item {
  color: #707070;
  cursor: pointer;
  list-style: none;
  margin: 0 !important;
  overflow-x: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.dropdown-btn {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.5em;
  overflow: visible;
  padding: 10px 25px 10px 20px;
  padding: 7px 20px 7px 15px;
  text-align: left;
  width: 100%;
}

.dropdown-btn:hover {
  background-color: #cf1f39;
  color: #fff;
  text-decoration: none;
}

.fade-in-up {
  animation: effect-fade-in-up .25s;
}

@keyframes effect-fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.notification-show {
  animation-name: notification-fade-in;
  animation-duration: .7s;
  animation-fill-mode: both;
}

.notification-hide {
  animation-name: notification-fade-out;
  animation-duration: .7s;
  animation-fill-mode: both;
}

@keyframes notification-fade-in {
  from {
    opacity: 0;
    transform: translate3d(-50%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes notification-fade-out {
  from {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-50%, -100%, 0);
  }
}

.fade-in {
  animation: effect-fade-in .5s;
}

@keyframes effect-fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.fade-out {
  animation: effect-fade-out .25s;
  animation-fill-mode: forwards;
}

@keyframes effect-fade-out {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.menu-show {
  animation-name: menu-rotate;
  animation-duration: .2s;
  animation-fill-mode: both;
}

@keyframes menu-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.menu-hide {
  animation-name: menu-rotate-reverse;
  animation-duration: .2s;
  animation-fill-mode: both;
}

@keyframes menu-rotate-reverse {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}


@tailwind utilities;
